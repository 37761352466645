import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppSelector, useCurrentUser } from 'app/helpers';
import { Page } from 'app/components';
import { PrivateRouteState } from 'app/components/privateRoute/privateRoute';
import { AccessDeniedSection } from 'app/features/accessDenied/components/accessDeniedSection';
import { LoggingSection } from 'app/features/accessDenied/components/loggingSection';

export const AccessDeniedComponent: React.FC = () => {
  const userLoading = useAppSelector((state) => state.auth.whileFetching);
  const { isAuthenticated } = useCurrentUser();
  const { push } = useHistory();
  const location = useLocation<PrivateRouteState>();

  const path = location?.state?.referrer ?? '/';

  useEffect(() => {
    if (userLoading === false && isAuthenticated) {
      push(path);
    }
  }, [userLoading, isAuthenticated, push, path]);

  return <Page className="text-center py-6">{userLoading ? <LoggingSection /> : <AccessDeniedSection />}</Page>;
};
