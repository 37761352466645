import { Option } from './Option';

export enum BillingItemsTabs {
  BranchBillingItems = 'branch-billing-items',
  PractitionersBillingItems = 'practitioners-billing-items',
}

export interface BillingItem {
  branchId: string;
  clinicyId: string;
  cost: number;
  descriptionAr?: string;
  descriptionEn?: string;
  generalItemListNames: Option[];
  id: string;
  insuranceCompanies: BillingItemInsuranceCompany[];
  internalCode?: string;
  itemType: Option;
  linkToAllSpecialities: boolean;
  linkToAllSubSpecialities: boolean;
  hasCustomActivatedLinks: boolean;
  measureValue: number;
  medicalBilling: string;
  minimumSellingPrice: number;
  nameAr: string;
  nameEn: string;
  nameInInvoiceAr?: string;
  nameInInvoiceEn?: string;
  performerNames: Option[];
  price: number;
  saudisVatExempted: boolean;
  specialities: Option[];
  subSpecialities: Option[];
  unitMeasure: Option;
  isEnabled: boolean;
  isMainBillingItem: boolean;
}

export interface BillingItemMoreInfo extends BillingItem {
  matchingAssignedPractitioners: Option[];
  notMatchingAssignedPractitioners: Option[];
}

export interface BillingItemInsuranceCompany {
  companyId: string;
  companyNameAr: string;
  companyNameEn: string;
  price: number;
  activated?: boolean;
}

export interface BillingItemInsuranceCompanyOption extends Option {
  details: {
    price: number;
  };
}

export interface BillingItemInsuranceItem {
  insuranceCompanyId: string;
  price: number;
}

export enum BillingItemType {
  Consultation = '1',
  Service = '2',
  Procedure = '3',
  Lab = '4',
  Radiology = '5',
  MedicalProduct = '6',
  NonMedicalProduct = '7',
  Consumables = '8',
}

export enum BillingItemSourceType {
  BillingItem = '1',
  Medication = '2',
  Package = '3',
}

export enum BillingItemHierarchyType {
  Regular = '0',
  Medication = '1',
}
