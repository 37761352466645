import { put, select } from 'redux-saga/effects';
import { invoicesApi } from 'app/services/invoices/invoices';
import { InvoicesItemsTabs, RootState } from 'app/types';

export function* refreshInvoiceList() {
  const {
    router: {
      location: { pathname },
    },
    queryParams: { invoices: invoicesParams, invoicesRequests: requestsParams },
  }: RootState = yield select();

  const isInvoiceList = pathname.includes(`invoices/${InvoicesItemsTabs.InvoicesItems}`);
  const isInvoiceRequestList = pathname.includes(`invoices/invoices-requests-items`);

  if (isInvoiceList) {
    yield put<any>(
      invoicesApi.endpoints?.getInvoices.initiate(invoicesParams, { subscribe: false, forceRefetch: true }),
    );
    return;
  }

  if (isInvoiceRequestList) {
    yield put<any>(
      invoicesApi.endpoints?.getInvoicesRequests.initiate(
        { ...requestsParams },
        { subscribe: false, forceRefetch: true },
      ),
    );
  }
}
