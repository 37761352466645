import { ChangeEvent, useCallback } from 'react';
import { change } from 'redux-form';
import { EventWithDataHandler } from 'redux-form/lib/Field';
import { useAppDispatch, useAppSelector, useCurrentUser } from 'app/helpers';
import { DEFAULT_TIME_FROM, DEFAULT_TIME_TO } from 'app/shared';
import {
  fetchDoctorSubSpecialities,
  fetchSubSpecialities,
  selectBranch,
  selectDoctor,
  selectService,
  selectSlot,
  selectSpeciality,
  selectSubSpeciality,
} from 'app/redux/book-appointment/book-appointment.actions';
import { DoctorData } from 'app/redux/book-appointment/types';
import { fetchTableViewItems, fetchWeeks, setTableItemsParams } from 'app/redux/calendar/calendar.actions';
import { SelectedDay } from 'app/redux/calendar/types';
import { fetchDropdownOptions } from 'app/redux/dropdownOptions/dropdownOptions.actions';
import { Option, PatientSourceOption, RemoteDataParams } from 'app/types';
import { useClinicId } from 'app/features/bookAppointment/helpers';
import { formConfig } from 'app/features/bookAppointment/shared';
import { BookAppointmentWizardField, StepType, TimeRange } from 'app/features/bookAppointment/types';
import { generateCalendarSearchParams } from './generateCalendarSerachParams';
import { generateCalendarUpdateParams } from './generateCalendarUpdateParams';

interface Props {
  setCurrentStep: (step: StepType) => void;
  setSelectedDay: (day: (previousDay: SelectedDay | null) => SelectedDay | null) => void;
  selectedBranch: Option | null;
  selectedPatientSource: PatientSourceOption | null;
  handleFetchBranches: (patientSourceId?: string) => void;
}

export const useWizardHandlers = ({
  setCurrentStep,
  setSelectedDay,
  selectedBranch,
  selectedPatientSource,
  handleFetchBranches,
}: Props) => {
  const dispatch = useAppDispatch();
  const { isDoctor } = useCurrentUser();

  // Selectors
  const clinicId = useClinicId();
  // @ts-ignore
  const { customAppointmentSlots, timeFrom, timeTo } = useAppSelector(
    (state) => state.form?.[formConfig.form]?.values || {},
  );
  const { data: calendarItems, params: calendarParams } = useAppSelector((state) => state.calendar.tableViewItems);
  const { selectedDoctor, selectedService, selectedSpeciality, selectedSubSpeciality, referralInfo } = useAppSelector(
    (state) => state.bookAppointment,
  );
  const doctorFilter = useAppSelector((state) => state.form[formConfig.form]?.values?.doctor);

  // Actions
  const fetchCalendarItems = useCallback(
    (params?: RemoteDataParams, service?: Option | null, doctor?: Option) => {
      if (selectedBranch && selectedSubSpeciality && selectedSpeciality) {
        dispatch(
          fetchTableViewItems(
            generateCalendarSearchParams({
              branchId: selectedBranch.value,
              clinicId: clinicId,
              specialityId: selectedSpeciality.value,
              subSpecialityId: selectedSubSpeciality.value,
              // @ts-ignore
              productName: service ? service.label : selectedService.label,
              doctorId: doctor ? doctor.value : selectedDoctor?.id,
              timeFrom: timeFrom?.value,
              timeTo: timeTo?.value,
              customAppointmentSlots: customAppointmentSlots,
              patientSourceId: selectedPatientSource?.value,
              params: params,
            }),
          ),
        );
      }
    },
    [
      dispatch,
      clinicId,
      customAppointmentSlots,
      timeFrom,
      timeTo,
      selectedBranch,
      selectedDoctor,
      selectedPatientSource,
      selectedSubSpeciality,
      selectedSpeciality,
      selectedService,
    ],
  );

  const handleFetchCalendarItems = useCallback(
    (params: RemoteDataParams) => fetchCalendarItems(params, selectedService, doctorFilter),
    [fetchCalendarItems, selectedService, doctorFilter],
  );

  const handleBranchChange = useCallback(
    (branch: Option | null) => {
      dispatch(selectBranch(branch, isDoctor, isDoctor || !!referralInfo));
      dispatch(selectSlot(null));
    },
    [dispatch, isDoctor, referralInfo],
  );

  const handleCustomizedAppointmentSlot = useCallback(
    (customizedDuration?: Option) => {
      dispatch(selectSlot(null));
      if (selectedService) {
        if (calendarParams) {
          dispatch(
            setTableItemsParams({
              ...calendarParams,
              productRelatedFilters: {
                ...calendarParams.productRelatedFilters,
                customDurationInMinutes: customizedDuration?.value,
              },
            }),
          );
        }

        const params = generateCalendarUpdateParams(calendarItems, calendarParams || {}, customizedDuration?.value);

        if (params.length > 0) {
          dispatch(fetchWeeks(params));
        }
      }
    },
    [dispatch, calendarItems, calendarParams, selectedService],
  );

  const handleTimeChange = useCallback(
    ({ value, name }: { value?: string; name: string }) => {
      dispatch(selectSlot(null));
      if (selectedService) {
        const timeRange: TimeRange = { ...calendarParams, [name]: value };
        if (calendarParams) {
          dispatch(
            setTableItemsParams({
              ...calendarParams,
              timeFrom: timeRange.timeFrom || DEFAULT_TIME_FROM,
              timeTo: timeRange.timeTo || DEFAULT_TIME_TO,
            }),
          );
        }

        const params = generateCalendarUpdateParams(calendarItems, timeRange, customAppointmentSlots?.value);

        if (params.length > 0) {
          dispatch(fetchWeeks(params));
        }
      }
    },
    [dispatch, calendarItems, calendarParams, customAppointmentSlots, selectedService],
  );

  const handleDoctorChange = useCallback(
    (doctor: DoctorData) => {
      dispatch(selectDoctor(doctor));
      setCurrentStep(StepType.SubSpeciality);
      if (selectedBranch) {
        dispatch(fetchDoctorSubSpecialities(doctor.id, selectedBranch.value, selectedPatientSource?.value));
      }
      dispatch(selectSlot(null));
      dispatch(change(formConfig.form, BookAppointmentWizardField.doctor, null));
    },
    [dispatch, setCurrentStep, selectedBranch, selectedPatientSource],
  );

  const handleSpecialityChange = useCallback(
    (speciality: Option) => {
      dispatch(selectSpeciality(speciality));
      setCurrentStep(StepType.SubSpeciality);
      if (selectedBranch) {
        dispatch(fetchSubSpecialities(speciality.value, selectedBranch.value, selectedPatientSource?.value));
      }
      dispatch(selectSlot(null));
    },
    [dispatch, selectedBranch, setCurrentStep, selectedPatientSource],
  );

  const handleSubSpecialityChange = useCallback(
    (subSpeciality: Option) => {
      dispatch(selectSubSpeciality(subSpeciality));
      setCurrentStep(StepType.ServiceWithWorkingDay);
      dispatch(selectSlot(null));
    },
    [dispatch, setCurrentStep],
  );

  const handleServiceChange = useCallback(
    (service: Option) => {
      dispatch(selectService(service));
      setSelectedDay(() => null);
      dispatch(selectSlot(null));

      if (service) {
        fetchCalendarItems(calendarParams, service, doctorFilter);
      }
    },
    [dispatch, calendarParams, doctorFilter, fetchCalendarItems, setSelectedDay],
  );

  const handleSelectedDay = useCallback(
    (day: SelectedDay) => {
      dispatch(selectSlot(null));
      setSelectedDay((previousDay: SelectedDay | null) => {
        return !previousDay ||
          previousDay.branchId !== day.branchId ||
          previousDay.date !== day.date ||
          previousDay.doctorId !== day.doctorId
          ? day
          : null;
      });
    },
    [dispatch, setSelectedDay],
  );

  const handleDoctorFilterChange = useCallback(
    (e: ChangeEvent, doctor?: Option) => {
      setSelectedDay(() => null);
      dispatch(selectSlot(null));
      fetchCalendarItems(calendarParams, selectedService, doctor);
    },
    [dispatch, calendarParams, fetchCalendarItems, setSelectedDay, selectedService],
  );

  const fetchDoctorFilterOptions = useCallback(
    (params: RemoteDataParams) => {
      return dispatch(
        fetchDropdownOptions('wizard/speciality-path/step3/doctors/dropdown', {
          ...params,
          clinicId,
          branchId: selectedBranch?.value,
          specialityId: selectedSpeciality?.value,
          subSpecialityId: selectedSubSpeciality?.value,
          productName: selectedService?.value,
          patientSourceId: selectedPatientSource?.value,
        }),
      );
    },
    [
      dispatch,
      clinicId,
      selectedBranch,
      selectedSpeciality,
      selectedSubSpeciality,
      selectedService,
      selectedPatientSource,
    ],
  );

  const handlePatientSourceChange: EventWithDataHandler<ChangeEvent> = useCallback(
    (_, value?: PatientSourceOption) => {
      dispatch(selectBranch(null, isDoctor, isDoctor || !!referralInfo));
      dispatch(selectSlot(null));
      handleFetchBranches(value?.value);
      setCurrentStep(isDoctor ? StepType.SubSpeciality : StepType.Speciality);
    },
    [dispatch, isDoctor, referralInfo, setCurrentStep, handleFetchBranches],
  );

  return {
    fetchDoctorFilterOptions,
    handleBranchChange,
    handleCustomizedAppointmentSlot,
    handleDoctorChange,
    handleDoctorFilterChange,
    handleFetchCalendarItems,
    handlePatientSourceChange,
    handleSpecialityChange,
    handleSubSpecialityChange,
    handleServiceChange,
    handleSelectedDay,
    handleTimeChange,
  };
};
