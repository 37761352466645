import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useAppIntl } from 'app/helpers';
import { Page } from 'app/components';
import { ErrorStatus } from 'app/types';

interface Props {
  errorStatus: ErrorStatus;
}

const errors = [
  {
    status: ErrorStatus.NotFound,
    title: 'ERROR-PAGE.TEXT.404',
    description: 'ERROR-PAGE.TEXT.DESCRIPTION-404',
  },
  {
    status: ErrorStatus.ServerError,
    title: 'ERROR-PAGE.TEXT.SOMETHING-WENT-WRONG',
    description: 'ERROR-PAGE.TEXT.DESCRIPTION-500',
  },
  {
    status: ErrorStatus.Offline,
    title: 'ERROR-PAGE.TEXT.OFFLINE',
    description: 'ERROR-PAGE.TEXT.DESCRIPTION-OFFLINE',
  },
  {
    status: ErrorStatus.UnderConstruction,
    title: 'ERROR-PAGE.TEXT.CONSTRUCTION-WORK',
    description: 'ERROR-PAGE.TEXT.DESCRIPTION-CONSTRUCTION',
  },
];

export const ErrorPage = ({ errorStatus }: Props) => {
  const { formatMessage } = useAppIntl();
  const error = errors.find((e) => e.status === errorStatus) || errors[0];

  return (
    <Page className="text-center py-6">
      <h1 className="mb-2">{formatMessage({ id: error.title })}</h1>
      <h3 className="fw-light mb-4">{formatMessage({ id: error.description })}</h3>
      <Button color="primary-gradient" tag={Link} to="/">
        <FormattedMessage id="CORE.BUTTON.HOME-PAGE" />
      </Button>
    </Page>
  );
};
