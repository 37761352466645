import {
  IconBuilding,
  IconBuildingCommunity,
  IconCalendarTime,
  IconCheckbox,
  IconFileImport,
  IconFriends,
  IconGauge,
  IconNotification,
  IconReportMoney,
  IconSettings,
  IconStethoscope,
  IconUser,
  IconUsers,
  IconWallet,
} from '@tabler/icons-react';
import { generateDashboardPath } from 'app/routes/dashboard/helpers';
import { insuranceContractorsSetupTab } from 'app/routes/insurancesManagement/constants';
import { generateInsuranceContractsSetUpPath } from 'app/routes/insurancesManagement/helpers';
import {
  generalSettingsPath,
  iconLibraryPath,
  insuranceCompaniesPath,
  medicalLibrariesPathCore,
  patientClassificationPath,
  specialitiesPath,
} from 'app/routes/settings/constants';
import {
  InsuranceApprovalsTabs,
  InsuranceContractorsScope,
  NavigationItem,
  NavigationSubItem,
  UserPermissions,
} from 'app/types';

const ICON_SIZE = 24;
const STROKE_WIDTH = 1.8;

export const appointments: NavigationItem = {
  icon: <IconCheckbox size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />,
  id: 'ni-appointments',
  text: 'CORE.LINK.APPOINTMENTS',
  to: '/appointments',
  subItems: [],
};

export const calendar: NavigationItem = {
  icon: <IconCalendarTime size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />,
  id: 'ni-calendar',
  text: 'CALENDAR.TEXT.CALENDAR',
  to: '/calendar',
  subItems: [],
};

export const financialSubItems: NavigationItem = {
  icon: <IconReportMoney size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />,
  id: 'ni-financial',
  text: 'CORE.LINK.INVOICES',
  to: '#',
  subItems: [
    {
      id: 'sni-invoices-items',
      text: 'INVOICES.TEXT.ISSUED-INVOICES',
      to: '/financial/invoices/invoices-items',
    },
    {
      id: 'sni-draft-items',
      text: 'INVOICES.TEXT.INVOICES_DRAFT',
      to: '/financial/invoices/invoices-draft-items',
    },
    {
      id: 'sni-invoice-request',
      text: 'INVOICES.TEXT.INVOICES_REQUESTS',
      to: '/financial/invoices/invoices-requests-items',
    },
  ],
};
export const financialList: NavigationItem = {
  icon: <IconReportMoney size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />,
  id: 'ni-financial',
  text: 'CORE.LINK.INVOICES',
  to: '/financial/invoices/invoices-items',
  subItems: [],
};

export const dashboard: NavigationItem = {
  icon: <IconGauge size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />,
  id: 'ni-dashboard',
  text: 'CORE.LINK.DASHBOARD',
  to: generateDashboardPath('appointments-summary'),
  subItems: [],
};

export const medicalInstitutions: NavigationItem = {
  icon: <IconBuildingCommunity size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />,
  id: 'ni-medicalInstitutions',
  text: 'CLINICS.TEXT.CLINICS',
  to: '#',
  subItems: [
    {
      id: 'sni-medicalInstitutions-1',
      text: 'CORE.LINK.REGISTERED',
      to: '/clinics',
    },
    {
      id: 'sni-medicalInstitutions-2',
      text: 'CORE.LINK.PENDING',
      to: '/clinics/pending',
    },
    {
      id: 'sni-medicalInstitutions-3',
      text: 'CLINICS.TEXT.REQUESTS',
      to: '/clinics/requests/current',
    },
    {
      id: 'sni-medicalInstitutions-4',
      text: 'CORE.LINK.REJECTED',
      to: '/clinics/rejected',
    },
    {
      id: 'sni-medicalInstitutions-5',
      text: 'CORE.BUTTON.DELETED',
      to: '/clinics/deleted',
    },
  ],
};

export const medicalInstitution: NavigationItem = {
  icon: <IconBuilding size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />,
  id: 'ni-medicalInstitution',
  text: 'CORE.LABEL.MEDICAL-INSTITUTION',
  to: '', // depends on the role
  subItems: [],
};

export const getAccountBalance = (permissions: UserPermissions, clinicId?: string): NavigationItem | undefined => {
  if (permissions.allowedToViewClinicAccountBalance) {
    const to = clinicId ? `/clinics/${clinicId}/account-balance/patient-account-balances` : '/clinics/account-balances';

    return {
      icon: <IconWallet size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />,
      id: 'ni-accountBalance',
      text: 'CORE.LINK.ACCOUNT-BALANCE',
      to,
      subItems: [],
    };
  }
};

export const getPatients = (permissions: UserPermissions, isAdminUser: boolean, clinicId?: string): NavigationItem => {
  const subItems: NavigationSubItem[] = [];

  if (isAdminUser) {
    subItems.push({
      id: 'ni-patients-list',
      text: 'CORE.LINK.PATIENTS-LIST',
      to: '/patients',
    });
  } else {
    subItems.push({
      id: 'ni-patients-list',
      text: 'CORE.LINK.PATIENTS-LIST',
      to: `/clinics/${clinicId}/patients`,
    });
  }

  if (permissions.allowedForEMR) {
    subItems.push({
      id: 'ni-patients-arrival',
      text: 'CORE.TEXT.PATIENTS-ARRIVAL',
      to: '/patients-arrival',
    });
  }

  if (permissions.allowedForViewReferral) {
    subItems.push({
      id: 'ni-referrals',
      text: 'CORE.TEXT.REFERRALS',
      to: '/referrals/current',
    });
  }

  if (permissions.allowedForViewOrders) {
    subItems.push({
      id: 'ni-patient-orders',
      text: 'CORE.TEXT.ORDERS',
      to: '/orders',
    });
  }

  if (clinicId && permissions.allowedForViewPatientSources) {
    subItems.push({
      id: 'ni-patient-patient-sources',
      text: 'CORE.LABEL.PATIENT-SOURCES',
      to: `/clinics/${clinicId}/patient-sources`,
    });
  }

  return {
    icon: <IconFriends size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />,
    id: 'ni-patients',
    text: 'CORE.LINK.PATIENTS',
    to: '#',
    subItems,
  };
};

export const getMassAdding = (permissions: UserPermissions): NavigationItem | undefined => {
  if (!permissions.allowedForMassAdding) {
    return;
  }

  return {
    icon: <IconFileImport size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />,
    id: 'ni-massAdding',
    text: 'CORE.LINK.MASS-ADDING',
    to: '#',
    subItems: [
      {
        id: 'ni-massAdding-import',
        text: 'CORE.LINK.IMPORT',
        to: '/mass-adding/imports',
      },
      {
        id: 'ni-massAdding-files',
        text: 'CORE.LINK.FILES',
        to: '/mass-adding/files',
      },
    ],
  };
};

export const getInsuranceManagement = (permissions: UserPermissions): NavigationItem | undefined => {
  const subItems: NavigationSubItem[] = [];

  if (permissions.allowedForInsuranceManagement && permissions.allowedForInsuranceApproval) {
    subItems.push({
      id: 'sni-insuranceManagement-1',
      text: 'CORE.LABEL.APPROVALS',
      to: `/insurances-management/${InsuranceApprovalsTabs.Approvals}`,
    });
  }

  if (permissions.allowedForInsuranceManagement && permissions.allowedForReferralApproval) {
    subItems.push({
      id: 'sni-insuranceManagement-2',
      text: 'CORE.LABEL.REFERRAL-IN-APPROVALS',
      to: `/insurances-management/${InsuranceApprovalsTabs.Referrals}`,
    });
  }

  if (permissions.allowedForInsuranceManagement && permissions.allowedForViewInsuranceContractsAndPolicies) {
    subItems.push({
      id: 'sni-insuranceManagement-3',
      text: 'CORE.LABEL.INSURANCE-CONTRACT-AND-POLICY-SET-UP',
      to: generateInsuranceContractsSetUpPath(
        insuranceContractorsSetupTab.contractPolicySetup,
        InsuranceContractorsScope.Global,
      ),
    });
  }

  if (permissions.allowedForInsuranceManagement && permissions.allowedForViewInsuranceClaim) {
    subItems.push({
      id: 'sni-insuranceManagement-4',
      text: 'INSURANCE-CLAIMS.LABEL.CLAIMS',
      to: '/insurances-management/claims/unclaimed',
    });
  }

  return subItems.length
    ? {
        icon: <IconFriends size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />,
        id: 'ni-insuranceManagement',
        text: 'CORE.LABEL.INSURANCE-MANAGEMENT',
        to: '#',
        subItems,
      }
    : undefined;
};

export const userRoles: NavigationItem = {
  icon: <IconUsers size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />,
  id: 'ni-userRoles',
  text: 'CORE.TEXT.USERS',
  to: '/user/roles',
  subItems: [],
};

export const clinicUserRoles: NavigationItem = {
  icon: <IconUsers size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />,
  id: 'ni-clinicUserRoles',
  text: 'CORE.TEXT.USERS',
  to: '', // depends on the role
  subItems: [],
};

export const settings: NavigationItem = {
  icon: <IconSettings size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />,
  id: 'ni-settings',
  text: 'CORE.LINK.SETTINGS',
  to: '#',
  subItems: [
    {
      id: 'sni-settings-1',
      text: 'CORE.LINK.SPECIALITIES',
      to: specialitiesPath,
    },
    {
      id: 'sni-settings-2',
      text: 'CORE.LINK.GENERAL-SETTINGS',
      to: generalSettingsPath,
    },
    {
      id: 'sni-settings-3',
      text: 'CORE.LINK.INSURANCE-SETUP',
      to: insuranceCompaniesPath,
    },
    {
      id: 'sni-settings-4',
      text: 'CORE.LABEL.ICON-LIBRARY',
      to: iconLibraryPath,
    },
    {
      id: 'sni-settings-5',
      text: 'CORE.LINK.MEDICAL_LIBRARIES',
      to: medicalLibrariesPathCore,
    },
    {
      id: 'sni-settings-6',
      text: 'CORE.LINK.PATIENT-CLASSIFICATION-SET-UP',
      to: patientClassificationPath,
    },
  ],
};

export const notification: NavigationItem = {
  icon: <IconNotification size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />,
  id: 'ni-notification',
  text: 'CORE.LINK.NOTIFICATIONS',
  to: '#',
  subItems: [
    {
      id: 'sni-notification-1',
      text: 'NOTIFICATIONS.TEXT.MANAGE-NOTIFICATION-CONTENT',
      to: '/notifications/content',
    },
    {
      id: 'sni-notification-2',
      text: 'NOTIFICATIONS.TEXT.ENABLING-NOTIFICATION',
      to: '/notifications/enabling',
    },
    {
      id: 'sni-notification-3',
      text: 'NOTIFICATIONS.TEXT.NOTIFICATION-MASS-MESSAGING',
      to: '/notifications/mass-messaging',
    },
    {
      id: 'sni-notification-4',
      text: 'NOTIFICATIONS.TEXT.SCHEDULED-NOTIFICATION',
      to: '/notifications/scheduled',
    },
    {
      id: 'sni-notification-5',
      text: 'NOTIFICATIONS.TEXT.NOTIFICATION-LOG',
      to: '/notifications/log/current',
    },
  ],
};

export const branches: NavigationItem = {
  icon: <IconBuildingCommunity size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />,
  id: 'ni-branches',
  text: 'CORE.LABEL.BRANCHES',
  to: '', // depends on the role
  subItems: [],
};

export const practitioners: NavigationItem = {
  icon: <IconStethoscope size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />,
  id: 'ni-practitioners',
  text: 'CORE.LABEL.DOCTORS',
  to: '', // depends on the role
  subItems: [],
};

export const practitioner: NavigationItem = {
  icon: <IconUser size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />,
  id: 'ni-practitioner',
  text: 'CORE.LABEL.DOCTOR',
  to: '', // depends on the role
  subItems: [],
};

export const patientsList: NavigationItem = {
  icon: <IconFriends size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />,
  id: 'ni-patients',
  text: 'CORE.LINK.PATIENTS-LIST',
  to: '/patients',
  subItems: [],
};
