import { useMemo } from 'react';
import { useCurrentUser, useCurrentUserPermissions } from 'app/helpers';
import * as items from 'app/components/menu/navigation/helpers/navigationItems';
import { removeEmptyItems } from 'app/components/menu/navigation/helpers/removeEmptyItems';
import { appointmentTab } from 'app/routes/appointments/constants';
import { generateDoctorAppointmentsPath } from 'app/routes/appointments/helpers';
import { branchesTab } from 'app/routes/clinics/constants';
import { generateClinicBranchesPath } from 'app/routes/clinics/helpers';
import { doctorsTab } from 'app/routes/doctors/constants';
import { generateDoctorsPath } from 'app/routes/doctors/helpers';
import { NavigationItem } from 'app/types';

export const useNavigationItems = (): NavigationItem[] => {
  const {
    currentUser,
    isAdmin,
    isDoctor,
    isManager,
    isManagerAdmin,
    isOwner,
    isReception,
    isReceptionAdmin,
    isViewer,
    isNurse,
  } = useCurrentUser();
  const userId = currentUser.id;
  const clinicId = currentUser.clinic?.value;
  const permissions = useCurrentUserPermissions();

  return useMemo<NavigationItem[]>(() => {
    if (isAdmin) {
      return removeEmptyItems([
        items.dashboard,
        items.appointments,
        items.calendar,
        items.medicalInstitutions,
        items.getPatients(permissions, true),
        items.getMassAdding(permissions),
        items.userRoles,
        items.financialSubItems,
        items.getAccountBalance(permissions),
        items.getInsuranceManagement(permissions),
        items.settings,
        items.notification,
      ]);
    }

    if (isManagerAdmin) {
      return removeEmptyItems([
        items.dashboard,
        items.appointments,
        items.calendar,
        items.medicalInstitutions,
        items.getPatients(permissions, true),
        items.getMassAdding(permissions),
        ...(permissions.allowedForViewInvoice ? [items.financialSubItems] : []),
        items.getAccountBalance(permissions),
        items.getInsuranceManagement(permissions),
        items.notification,
      ]);
    }

    if (isReceptionAdmin) {
      return removeEmptyItems([
        items.dashboard,
        items.appointments,
        items.calendar,
        {
          ...items.medicalInstitutions,
          subItems: [],
          to: '/clinics',
        },
        items.getPatients(permissions, true),
        ...(permissions.allowedForViewInvoice ? [items.financialSubItems] : []),
        items.getAccountBalance(permissions),
        items.getInsuranceManagement(permissions),
      ]);
    }

    if (isManager && clinicId) {
      return removeEmptyItems([
        ...(permissions.allowedForDashboard ? [items.dashboard] : []),
        items.appointments,
        items.calendar,
        { ...items.branches, to: generateClinicBranchesPath(branchesTab.active, clinicId) },
        { ...items.practitioners, to: generateDoctorsPath(doctorsTab.active, clinicId) },
        items.getPatients(permissions, false, clinicId),
        { ...items.clinicUserRoles, to: `/clinics/${clinicId}/users` },
        ...(permissions.allowedForViewInvoice && permissions.allowedForEMR
          ? [items.financialSubItems]
          : permissions.allowedForViewInvoice
          ? [items.financialList]
          : []),
        items.getAccountBalance(permissions, clinicId),
        items.getInsuranceManagement(permissions),
      ]);
    }

    if (isReception && clinicId) {
      return removeEmptyItems([
        ...(permissions.allowedForDashboard ? [items.dashboard] : []),
        items.appointments,
        items.calendar,
        { ...items.branches, to: generateClinicBranchesPath(branchesTab.active, clinicId) },
        { ...items.practitioners, to: generateDoctorsPath(doctorsTab.active, clinicId) },
        items.getPatients(permissions, false, clinicId),
        ...(permissions.allowedForViewInvoice && permissions.allowedForEMR
          ? [items.financialSubItems]
          : permissions.allowedForViewInvoice
          ? [items.financialList]
          : []),
        items.getAccountBalance(permissions, clinicId),
        items.getInsuranceManagement(permissions),
      ]);
    }

    if (isOwner && clinicId) {
      return removeEmptyItems([
        items.dashboard,
        items.appointments,
        items.calendar,
        { ...items.medicalInstitution, to: `/clinics/${clinicId}` },
        { ...items.branches, to: generateClinicBranchesPath(branchesTab.active, clinicId) },
        { ...items.practitioners, to: generateDoctorsPath(doctorsTab.active, clinicId) },
        items.getPatients(permissions, false, clinicId),
        { ...items.clinicUserRoles, to: `/clinics/${clinicId}/users` },
        ...(permissions.allowedForViewInvoice && permissions.allowedForEMR
          ? [items.financialSubItems]
          : permissions.allowedForViewInvoice
          ? [items.financialList]
          : []),
        items.getAccountBalance(permissions, clinicId),
        items.getInsuranceManagement(permissions),
      ]);
    }

    if (isViewer && clinicId) {
      return removeEmptyItems([
        ...(permissions.allowedForDashboard ? [items.dashboard] : []),
        items.appointments,
        items.calendar,
        { ...items.branches, to: generateClinicBranchesPath(branchesTab.active, clinicId) },
        { ...items.practitioners, to: generateDoctorsPath(doctorsTab.active, clinicId) },
        items.getPatients(permissions, false, clinicId),
        ...(permissions.allowedForViewInvoice && permissions.allowedForEMR
          ? [items.financialSubItems]
          : permissions.allowedForViewInvoice
          ? [items.financialList]
          : []),
        items.getAccountBalance(permissions, clinicId),
        items.getInsuranceManagement(permissions),
      ]);
    }

    if (isNurse && clinicId) {
      return removeEmptyItems([
        ...(permissions.allowedForDashboard ? [items.dashboard] : []),
        items.appointments,
        items.calendar,
        items.getPatients(permissions, false, clinicId),
        {
          ...items.branches,
          to: generateClinicBranchesPath(branchesTab.active, clinicId),
        },
        { ...items.practitioners, to: generateDoctorsPath(doctorsTab.active, clinicId) },
        items.getAccountBalance(permissions, clinicId),
        ...(permissions.allowedForViewInvoice && permissions.allowedForEMR
          ? [items.financialSubItems]
          : permissions.allowedForViewInvoice
          ? [items.financialList]
          : []),
        items.getInsuranceManagement(permissions),
      ]);
    }

    if (isDoctor && userId && clinicId) {
      return removeEmptyItems([
        ...(permissions.allowedForDashboard ? [items.dashboard] : []),
        { ...items.appointments, to: generateDoctorAppointmentsPath(appointmentTab.today, userId) },
        {
          ...items.branches,
          to: generateClinicBranchesPath(branchesTab.active, clinicId),
        },
        items.calendar,
        items.getPatients(permissions, false, clinicId),
        { ...items.practitioner, to: `/clinics/${clinicId}/doctors/${userId}` },
        ...(permissions.allowedForViewInvoice && permissions.allowedForEMR
          ? [items.financialSubItems]
          : permissions.allowedForViewInvoice
          ? [items.financialList]
          : []),
        items.getInsuranceManagement(permissions),
      ]);
    }

    return [];
  }, [
    clinicId,
    isAdmin,
    isDoctor,
    isManager,
    isManagerAdmin,
    isNurse,
    isOwner,
    isReception,
    isReceptionAdmin,
    isViewer,
    permissions,
    userId,
  ]);
};
