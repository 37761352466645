import { lazy, Suspense, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useCurrentUser } from 'app/helpers';
import { PrivateRoute } from 'app/components/privateRoute/privateRoute';
import { isDevelopmentEnv, isTestingEnv } from 'app/constants';
import { Loader } from 'app/shared';
import { appointmentsPathCore, appointmentTab } from 'app/routes/appointments/constants';
import { generateDoctorAppointmentsPath, generateGeneralAppointmentsPath } from 'app/routes/appointments/helpers';
import { branchesPathCore } from 'app/routes/branches/constants';
import { patientPaymentsCore } from 'app/routes/patientPayments/constants';
import { settingsPathCore } from 'app/routes/settings/constants';
import { ErrorStatus, UserRoles } from 'app/types';
import { App } from 'app/app';
import { AccessDeniedComponent } from 'app/features/accessDenied/accessDenied';
import { ConfirmAppointmentContainer } from 'app/features/confirm-appointment/confirm-appointment.container';
import { MedicalProfile } from 'app/features/doctorAssessment/components/medicalProfileSection/medicalProfile';
import { ErrorPage } from 'app/features/errorPage/errorPage';
import { ForgottenPassword } from 'app/features/forgottenPassword/forgottenPassword';
import { HistoricalCreditNote } from 'app/historicalDocuments/historicalCreditNote/historicalCreditNote';
import { HistoricalInvoice } from 'app/historicalDocuments/historicalInvoice/historicalInvoice';
import { Login } from 'app/login/login';
import { ResetPassword } from 'app/resetPassword/resetPassword';

// Routes
const RegistrationRoutes = lazy(() => import('app/routes/registration/registration.routes'));
const AppointmentsRoutes = lazy(() => import('app/routes/appointments'));
const BranchesRoutes = lazy(() => import('app/routes/branches'));
const BillingItemsRoutes = lazy(() => import('app/routes/billingItems/billingItems.routes'));
const CalendarRoutes = lazy(() => import('app/routes/calendar/calendar.routes'));
const ClinicsRoutes = lazy(() => import('app/routes/clinics'));
const Dashboard = lazy(() => import('app/routes/dashboard'));
const DoctorsRoutes = lazy(() => import('app/routes/doctors'));
const NotificationsRoutes = lazy(() => import('app/routes/notifications/notifications.routes'));
const PatientsArrivalRoutes = lazy(() => import('app/routes/patientsArrival/patientsArrival.routes'));
const PatientsRoutes = lazy(() => import('app/routes/patients/patients.routes'));
const SettingsRoutes = lazy(() => import('app/routes/settings'));
const TreatmentsRoutes = lazy(() => import('app/routes/treatment'));
const UserRoutes = lazy(() => import('app/routes/user/user.routes'));
const InvoicesRoutes = lazy(() => import('app/routes/invoices/invoices.routes'));
const FinancialRoutes = lazy(() => import('app/routes/financial/financial.routes'));
const HangfireRoutes = lazy(() => import('app/routes/hangfire/hangfire.routes'));
const InsurancesManagement = lazy(() => import('app/routes/insurancesManagement'));
const ReferralsRoutes = lazy(() => import('app/routes/referrals/referrals.routes'));
const Orders = lazy(() => import('app/routes/orders/orders.routes'));
const ProfileRoute = lazy(() => import('app/routes/profile/profile.routes'));
const MassAdding = lazy(() => import('app/routes/massAdding/massAdding.routes'));
const Redesign = lazy(() => import('app/redesign/redesign'));
const PatientPayments = lazy(() => import('app/routes/patientPayments'));

export const Routes = () => {
  const {
    currentUser: { allowedForDashboard, id: userId },
    isDoctor,
  } = useCurrentUser();

  const defaultDenialPath = useMemo(() => {
    if (allowedForDashboard) {
      return '/dashboard/appointments-summary';
    }
    if (isDoctor && userId) {
      return generateDoctorAppointmentsPath(appointmentTab.today, userId);
    }
    return generateGeneralAppointmentsPath(appointmentTab.today);
  }, [allowedForDashboard, isDoctor, userId]);

  return (
    <App>
      <Suspense fallback={<Loader />}>
        <Switch>
          <PrivateRoute
            allowedRoles={[UserRoles.None]}
            component={Login}
            denialPath={defaultDenialPath}
            exact
            path="/"
          />
          <Route component={AccessDeniedComponent} path="/access-denied" />
          <Route component={AppointmentsRoutes} path={appointmentsPathCore} />
          <Route component={BranchesRoutes} path={branchesPathCore} />
          <Route path="/billing-items" component={BillingItemsRoutes} />
          <Route path="/medical-profile" component={MedicalProfile} />
          <Route path="/calendar" component={CalendarRoutes} />
          <Route path="/clinics" component={ClinicsRoutes} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/doctors" component={DoctorsRoutes} />
          <Route path="/profile" component={ProfileRoute} />
          <Route path="/patients-arrival" component={PatientsArrivalRoutes} />
          <Route path="/notifications" component={NotificationsRoutes} />
          <Route path="/patients" component={PatientsRoutes} />
          <Route path="/referrals" component={ReferralsRoutes} />
          <Route path="/registration" component={RegistrationRoutes} />
          <Route component={SettingsRoutes} path={settingsPathCore} />
          <Route path={patientPaymentsCore} component={PatientPayments} />
          <Route path="/treatment" component={TreatmentsRoutes} />
          <Route path="/insurances-management" component={InsurancesManagement} />
          <Route path="/user" component={UserRoutes} />
          <Route path="/invoices" component={InvoicesRoutes} />
          <Route path="/financial" component={FinancialRoutes} />
          <Route path="/confirm-appointment/:id" component={ConfirmAppointmentContainer} />
          <Route path="/orders" component={Orders} />
          <Route component={ForgottenPassword} path="/forgotten-password" />
          <Route component={ResetPassword} path="/password-reset" exact />
          <Route component={HistoricalInvoice} exact path="/hi/:invoiceId(.{36})" />
          <Route component={HistoricalCreditNote} exact path="/hcn/:creditNoteId(.{36})" />
          <Route component={MassAdding} path="/mass-adding" />
          <Route path="/hangfire" component={HangfireRoutes} />
          <Route path="/500" exact component={() => <ErrorPage errorStatus={ErrorStatus.ServerError} />} />
          <Route path="/offline" exact component={() => <ErrorPage errorStatus={ErrorStatus.Offline} />} />
          <Route path="/404" exact component={() => <ErrorPage errorStatus={ErrorStatus.NotFound} />} />
          <Route
            path="/maintenance"
            exact
            component={() => <ErrorPage errorStatus={ErrorStatus.UnderConstruction} />}
          />
          {(isDevelopmentEnv || isTestingEnv) && <Route path="/redesign" component={Redesign} />}
          <Route component={() => <ErrorPage errorStatus={ErrorStatus.NotFound} />} />
        </Switch>
      </Suspense>
    </App>
  );
};
