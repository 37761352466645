import React, { Fragment, useMemo } from 'react';
import draftToHtml from 'draftjs-to-html';
import moment from 'moment';
import { getFormInitialValues } from 'redux-form';
import { formattedDateAndTime, useAppIntl, useAppSelector } from 'app/helpers';
import { LocalBootstrapTable } from 'app/components/bootstrapTable/localBootstrapTable/localBootstrapTable';
import {
  FORMATS_DATE_AND_TIME,
  FORMS_ASSESSMENT_BILLING,
  FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM,
  FORMS_ASSESSMENT_PRESCRIPTIONS,
  FORMS_ASSESSMENT_PRINT_MODAL_FORM,
  FORMS_ASSESSMENT_SIGNATURE_FORM,
} from 'app/shared';
import { DiagnosesResponse } from 'app/redux/doctorAssessment/types';
import { CustomDescriptionList } from 'app/features/doctorAssessment/components/medicalProfileSection/components/customDescriptionList';
import {
  InitialAssessmentVitalsItems,
  useIcdCodesColumns,
  useLocationsColumns,
  usePastMedicalHistory,
  useServiceItemsColumns,
  useSurgicalHistory,
} from 'app/features/doctorAssessment/components/medicalProfileSection/components/helpers';
import { useReferralsColumns } from 'app/features/doctorAssessment/components/medicalProfileSection/components/helpers/columns/useReferralsColumns';
import { PrintDataFormatter } from 'app/features/doctorAssessment/components/medicalProfileSection/components/helpers/printDataFormatter/printDataFormatter';
import { useTableData } from 'app/features/doctorAssessment/components/medicalProfileSection/components/helpers/printVisitPractitionerAssessment/useTableData';
import { PrintModalFormData } from 'app/features/doctorAssessment/components/medicalProfileSection/components/modals/printModal/printModalBody';
import { MedicalProfileFormData } from 'app/features/doctorAssessment/components/medicalProfileSection/components/practitionerAssessment/practitionerAssessment';
import { usePrescriptionData } from 'app/features/doctorAssessment/components/prescriptionSection/components/prescriptionPrintTemplate/helpers/usePrescriptionData';
import { PrescriptionPrintBody } from 'app/features/doctorAssessment/components/prescriptionSection/components/prescriptionPrintTemplate/prescriptionPrintBody';
import { PrescriptionPrintHeader } from 'app/features/doctorAssessment/components/prescriptionSection/components/prescriptionPrintTemplate/prescriptionPrintHeader';
import {
  WrappedPrescriptionFormData,
  WrappedPrescriptionsFormData,
} from 'app/features/doctorAssessment/components/prescriptionSection/types';
import { SignaturePrintField } from 'app/features/doctorAssessment/components/signaturePrintField/signaturePrintField';
import { useCheckViewStatus } from 'app/features/doctorAssessment/helpers';
import styles from 'app/features/doctorAssessment/components/medicalProfileSection/components/helpers/printVisitPractitionerAssessment/printVisitPractitionerAssessment.module.scss';

export const PrintVisitAssessment = () => {
  const { formatMessage } = useAppIntl();
  const timeNow = moment().format(FORMATS_DATE_AND_TIME);
  const printHeaderData = usePrescriptionData();

  // Selectors
  const values: Partial<MedicalProfileFormData> | undefined = useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM]?.values,
  );
  const initialValues = useAppSelector((state) =>
    (getFormInitialValues(FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM) as any)(state),
  );
  const prescriptionsValues: WrappedPrescriptionsFormData | undefined = useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_PRESCRIPTIONS]?.values,
  );
  const servicesItemsValues = useAppSelector((state) => state.form[FORMS_ASSESSMENT_BILLING]?.values);
  const printValues: Partial<PrintModalFormData> | undefined = useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_PRINT_MODAL_FORM]?.values,
  );
  const signatureUrl = useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_SIGNATURE_FORM]?.values?.signatureFile.url,
  );
  const billingHistoricalData = useAppSelector((state) =>
    state.doctorAssessment.data?.previousBillingSections?.flatMap(
      (previousBillingItem) => previousBillingItem.assessmentBillingItems,
    ),
  );
  const prescriptionsHistoricalData = useAppSelector((state) =>
    state.doctorAssessment.data?.previousAssessmentPrescriptionSections?.flatMap(
      (historicalData) => historicalData.assessmentPrescriptions,
    ),
  );
  const assessmentData = useAppSelector((state) => state.doctorAssessment.data);
  const doctorName = useAppSelector(
    (state) => state.treatment.generalTreatmentInformation.data?.appointmentInformation.doctorName,
  );
  const pastMedicalHistoryTable = printValues?.pastMedicalHistoryTable?.filter((row) => row.toPrint);
  const surgicalHistoryTable = printValues?.surgicalHistoryTable?.filter((row) => row.toPrint);

  // Helper Hooks
  const {
    assessmentsData,
    chiefComplaintData,
    clinicalExaminationData,
    healthEducationData,
    procedureUpdateData,
    presentIllnessHistoryData,
    medicalConditionData,
    systemReviewData,
  } = useTableData();
  const { isContinue } = useCheckViewStatus(assessmentData?.viewStatus, assessmentData?.completedAt);
  const columnsIcdHistory = useIcdCodesColumns(true);
  const locationsColumns = useLocationsColumns();
  const serviceItemsColumns = useServiceItemsColumns();
  const pastMedicalHistoryColumns = usePastMedicalHistory();
  const surgicalHistoryColumns = useSurgicalHistory();
  const referralsColumns = useReferralsColumns();

  const billingItemsData = useMemo(() => {
    if (isContinue) {
      if (billingHistoricalData && servicesItemsValues?.assessmentBillingItems?.length > 0) {
        return [...billingHistoricalData, ...servicesItemsValues?.assessmentBillingItems];
      } else if (billingHistoricalData) {
        return billingHistoricalData;
      } else if (servicesItemsValues?.assessmentBillingItems?.length > 0) {
        return servicesItemsValues?.assessmentBillingItems;
      } else return [];
    } else {
      if (servicesItemsValues?.assessmentBillingItems?.length > 0) {
        return servicesItemsValues?.assessmentBillingItems;
      } else return [];
    }
  }, [billingHistoricalData, servicesItemsValues, isContinue]);

  const prescriptionsData: WrappedPrescriptionFormData[] = useMemo(() => {
    if (isContinue) {
      if (
        prescriptionsHistoricalData &&
        prescriptionsValues?.assessmentPrescriptions &&
        prescriptionsValues?.assessmentPrescriptions?.length > 0
      ) {
        return [
          ...prescriptionsHistoricalData.map((p) => ({
            prescriptionId: p.prescriptionId,
            assessmentPrescriptionDrugs: p.prescriptionDrugs,
          })),
          ...prescriptionsValues?.assessmentPrescriptions,
        ];
      } else if (prescriptionsHistoricalData) {
        return prescriptionsHistoricalData.map((p) => ({
          prescriptionId: p.prescriptionId,
          assessmentPrescriptionDrugs: p.prescriptionDrugs,
        }));
      } else if (
        prescriptionsValues?.assessmentPrescriptions &&
        prescriptionsValues?.assessmentPrescriptions?.length > 0
      ) {
        return prescriptionsValues?.assessmentPrescriptions;
      } else return [];
    } else {
      if (prescriptionsValues?.assessmentPrescriptions && prescriptionsValues?.assessmentPrescriptions?.length > 0) {
        return prescriptionsValues?.assessmentPrescriptions;
      } else return [];
    }
  }, [prescriptionsHistoricalData, prescriptionsValues, isContinue]);

  return (
    <div className="mt-4">
      {chiefComplaintData?.length > 0 && (
        <PrintDataFormatter title="TREATMENT-PLAN.LABEL.CHIEF-COMPLAINT" data={chiefComplaintData} />
      )}{' '}
      {presentIllnessHistoryData?.length > 0 && (
        <PrintDataFormatter title="TREATMENT-PLAN.LABEL.HISTORY-OF-PRESENT-ILLNESS" data={presentIllnessHistoryData} />
      )}
      {medicalConditionData?.length > 0 && (
        <PrintDataFormatter title="TREATMENT-PLAN.LABEL.MEDICAL-CONDITION" data={medicalConditionData} />
      )}
      {!!pastMedicalHistoryTable?.length && printValues?.pastMedicalHistory && (
        <div className={styles.icdTable}>
          <LocalBootstrapTable
            columns={pastMedicalHistoryColumns}
            data={pastMedicalHistoryTable}
            id="pastMedicalHistory"
            keyField="patientMedicalIllnessId"
            loading={false}
            classes={`table-sm mb-2`}
            wrapperClasses={styles.overflowHidden}
            showRowId={false}
          />
        </div>
      )}
      {!!surgicalHistoryTable?.length && printValues?.surgicalHistory && (
        <div className={styles.icdTable}>
          <LocalBootstrapTable
            columns={surgicalHistoryColumns}
            data={surgicalHistoryTable}
            id="surgicalHistory"
            keyField="patientSurgeryId"
            loading={false}
            classes={`table-sm mb-2`}
            wrapperClasses={styles.overflowHidden}
            showRowId={false}
          />
        </div>
      )}
      {systemReviewData?.length > 0 && (
        <PrintDataFormatter title="TREATMENT-PLAN.LABEL.REVIEW-OF-SYSTEM" data={systemReviewData} />
      )}{' '}
      {clinicalExaminationData?.length > 0 && (
        <PrintDataFormatter title="TREATMENT-PLAN.LABEL.CLINICAL-EXAMINATION" data={clinicalExaminationData} />
      )}
      {printValues?.vitals && (
        <div className={styles.pageBreak}>
          <InitialAssessmentVitalsItems />
        </div>
      )}
      {/*TODO: LAB REPORT HERE IN THE FUTURE*/}
      <div className={styles.icdTable}>
        {values?.icdTable && values?.icdTable?.length > 0 && (
          <>
            <div className="mb-2">
              <p className={styles.sectionSubtitle}>
                {formatMessage({ id: 'CORE.TABLE.DATE' })}: {timeNow}
              </p>
              {values.diagnosis && (
                <p
                  className={styles.sectionSubtitle}
                  dangerouslySetInnerHTML={{ __html: draftToHtml(values?.diagnosis) }}
                />
              )}
              <LocalBootstrapTable
                columns={columnsIcdHistory}
                data={values?.icdTable}
                id="icd"
                keyField="icdCode"
                loading={false}
                classes={`table-sm mb-2`}
                wrapperClasses={styles.overflowHidden}
                showRowId={false}
              />
            </div>
          </>
        )}
        {initialValues.diagnosisTable.flatMap((diagnose: DiagnosesResponse, index: number) => (
          <div className="mb-2" key={`diagnoses-${index}`}>
            {diagnose.text?.trim().length > 0 && (
              <>
                <p className={styles.sectionTitle}>{formatMessage({ id: 'CORE.TABLE.DESCRIPTION' })}: </p>
                <p className={styles.sectionSubtitle} dangerouslySetInnerHTML={{ __html: diagnose.text }} />
              </>
            )}
            <p className={styles.sectionTitle}>
              {formatMessage({ id: 'CORE.TABLE.DATE' })}: {formattedDateAndTime(diagnose.createdAt)}
            </p>
            {diagnose.diagnoses !== null && diagnose.diagnoses.length > 0 && (
              <LocalBootstrapTable
                columns={columnsIcdHistory}
                data={diagnose.diagnoses}
                id="diagnosis"
                keyField="icd10Code"
                loading={false}
                classes={`table-sm mb-2`}
                wrapperClasses={styles.overflowHidden}
                showRowId={false}
              />
            )}
          </div>
        ))}
      </div>
      {assessmentsData?.length > 0 && (
        <PrintDataFormatter title="TREATMENT-PLAN.TEXT.ASSESSMENT" data={assessmentsData} />
      )}
      {billingItemsData?.length > 0 && (
        <LocalBootstrapTable
          columns={serviceItemsColumns}
          data={billingItemsData}
          id="servicesItems"
          keyField="item"
          loading={false}
          classes={`table-sm mb-5`}
          wrapperClasses={styles.overflowHidden}
          showRowId={false}
        />
      )}
      {printValues?.plan && (
        <>
          {values?.procedureLocation?.notes && values?.procedureLocation?.notes?.length > 0 && (
            <LocalBootstrapTable
              columns={locationsColumns}
              data={values?.procedureLocation?.notes}
              id="locations"
              keyField="id"
              loading={false}
              classes={`table-sm mb-2`}
              wrapperClasses={styles.overflowHidden}
              showRowId={false}
            />
          )}
          {values?.plan && values?.plan?.blocks[0]?.text?.trim().length > 0 && (
            <CustomDescriptionList
              label={formatMessage({ id: 'TREATMENT-PLAN.LABEL.ADDITIONAL-PLAN-COMMENT' })}
              value={draftToHtml(values?.plan)}
              className="mb-2"
            />
          )}
        </>
      )}
      {printValues?.goal && (
        <>
          {values?.goalLocation?.notes && values?.goalLocation?.notes?.length > 0 && (
            <LocalBootstrapTable
              columns={locationsColumns}
              data={values?.goalLocation?.notes}
              id="goalLocations"
              keyField="id"
              loading={false}
              classes={`table-sm mb-2`}
              wrapperClasses={styles.overflowHidden}
              showRowId={false}
            />
          )}
          {values?.goal && values?.goal?.blocks[0]?.text?.trim().length > 0 && (
            <CustomDescriptionList
              label={formatMessage({ id: 'TREATMENT-PLAN.LABEL.ADDITIONAL-GOAL-COMMENT' })}
              value={draftToHtml(values?.goal)}
              className="mb-2"
            />
          )}
        </>
      )}
      {healthEducationData?.length > 0 && (
        <PrintDataFormatter title="TREATMENT-PLAN.LABEL.HEALTH-EDUCATION" data={healthEducationData} />
      )}
      {procedureUpdateData?.length > 0 && (
        <PrintDataFormatter title="TREATMENT-PLAN.LABEL.PROCEDURE-UPDATE" data={procedureUpdateData} />
      )}
      <div className={styles.pageBreak}>
        {printValues?.referrals &&
          assessmentData?.referralSection?.referrals &&
          assessmentData.referralSection?.referrals?.length > 0 && (
            <div className={styles.pageBreak}>
              <LocalBootstrapTable
                columns={referralsColumns}
                data={assessmentData.referralSection.referrals}
                id="assessmentReferrals"
                keyField="id"
                loading={false}
                classes={`table-sm mb-5`}
                wrapperClasses={styles.overflowHidden}
                showRowId={false}
              />
            </div>
          )}
      </div>
      {prescriptionsData && prescriptionsData?.length > 0 && printHeaderData && (
        <div className={styles.separatePage}>
          {prescriptionsData.map((prescription, index) => {
            return (
              <div key={index}>
                <PrescriptionPrintHeader data={printHeaderData} />
                <PrescriptionPrintBody
                  prescriptionData={prescription}
                  doctorName={doctorName}
                  signatureUrl={signatureUrl}
                  showSignature={false}
                />
              </div>
            );
          })}
        </div>
      )}
      <SignaturePrintField doctorName={doctorName} signatureUrl={signatureUrl} />
    </div>
  );
};
