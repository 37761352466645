import { Option } from 'app/types';

export const saudiVatExemptedOptions: Option<boolean>[] = [
  {
    value: true,
    label: 'CORE.LABEL.YES',
  },
  {
    value: false,
    label: 'CORE.LABEL.NO',
  },
];

export const enabledStatusOptions: Option<boolean>[] = [
  {
    value: true,
    label: 'CORE.LABEL.ACTIVE',
  },
  {
    value: false,
    label: 'CORE.LABEL.INACTIVE',
  },
];

export type StatusChangeHandler = (id: string, newStatus: boolean) => void | Promise<any>;
