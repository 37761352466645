import { PreferredLanguage } from 'app/shared';
import { RegisteredByOption, VerificationStatus, VerifiedBy } from 'app/types/ClinicyPatientProfile';
import { InsuranceClassOption, InsuranceCompany } from 'app/types/Insurance';
import { InsuranceContractEntityStatus } from 'app/types/InsuranceContract';
import { Option } from 'app/types/Option';
import { RemoteFile } from 'app/types/RemoteFile';
import { Status } from 'app/types/Status';

export enum PatientScope {
  MedicalInstitution = '0',
  Global = '1',
}

export enum PatientStatus {
  Active = '0',
  Deleted = '1',
  Blocked = '2',
  Splitted = '3',
  Merged = '4',
}

export enum PatientGender {
  Male = '0',
  Female = '1',
}

export interface PatientCategory {
  category?: Option;
  iconId?: string;
  iconFile: RemoteFile;
}

// TODO: Waiting for BE dictionary update
export enum PatientMaritalStatus {
  Single = '1',
  Married = '2',
  Separated = '3',
  Widow = '4',
  Other = '5',
}

export enum GovernmentIssuedIdType {
  SaudiId = '0',
  GccIdNumber = '1',
  VisaNumber = '2',
  BorderNumber = '3',
  TransitVisaNumber = '4',
  Passport = '5',
  Other = '999',
}

export enum PatientType {
  Regular = '0',
  Primary = '1',
  Dependent = '2',
}

export const PatientPhoneNumberProps = {
  id: 'id',
  phoneNumber: 'phoneNumber',
  isDefault: 'isDefault',
} as const;

export interface PatientPhoneNumber {
  [PatientPhoneNumberProps.id]?: string;
  [PatientPhoneNumberProps.phoneNumber]: string;
  [PatientPhoneNumberProps.isDefault]: boolean;
}

export interface PatientPhoneNumberOption extends Option {
  details: { isDefault: boolean; id: string };
}

export interface PatientBadges {
  clinicPatientId: string;
  generalNoteExists: boolean;
  hasAnyUnsettledInvoices: boolean;
  isAnyAllergyExists: boolean;
  isAnySpecialityNoteExists: boolean;
  isAppointmentNoteExists: boolean;
  isCompletelyNewPatient: boolean;
  isPatientVisitedManySpecialities: boolean;
  patientCategory: PatientCategory | null;
  wasReferred: boolean;
}

export interface MergedPatient {
  clinicPatientId: string;
  patientId: string;
  clinicyMrn: string;
}

export type Patient = {
  age: number;
  aliasNameArabic?: string;
  aliasNameEnglish?: string;
  city?: Option;
  clinic: Option;
  clinicPatientId: string;
  clinicyId: string;
  clinicyMrn: string;
  dateOfBirth?: string;
  email?: string;
  emergencyContactName?: string;
  emergencyContactNumber?: string;
  emergencyContactRelation?: Option<PatientEmergencyContactRelation>;
  familyNameArabic: string;
  familyNameEnglish: string;
  fatherNameArabic?: string;
  fatherNameEnglish?: string;
  firstNameArabic: string;
  firstNameEnglish: string;
  fullNameArabic: string;
  fullNameEnglish: string;
  gender?: Option<PatientGender>;
  generalNote?: string;
  governmentIssuedId?: string;
  governmentIssuedIdType?: Option<GovernmentIssuedIdType>;
  grandFatherNameArabic?: string;
  grandFatherNameEnglish?: string;
  guardians?: PatientGuardian[];
  hasMergePatientsBadge: boolean;
  id: string;
  internalFileNumber?: string;
  maritalStatus?: Option<PatientMaritalStatus>;
  mergedPatients: MergedPatient[];
  nationality?: Option;
  occupation?: Option<PatientOccupation>;
  otherEmergencyContactRelation?: string;
  otherMaritalStatus?: string;
  patientCategory?: Option;
  patientId: string;
  patientSource?: Option;
  patientType: Option<PatientType>;
  phoneNumbers: PatientPhoneNumber[];
  preferredNotificationLanguage?: Option<PreferredLanguage>;
  requireVerification?: boolean;
  status: Option<PatientStatus>;
  verificationStatus: Option<VerificationStatus>;
};

export type ClinicDetailedPatient = Patient & {
  previousFileNumbers?: string[];
  patientType: Option;
};

export interface PatientCategories {
  id: string;
  nameEn: string;
  nameAr: string;
  descriptionEn?: string;
  descriptionAr?: string;
  iconId?: Option;
  upcomingAppointmentsCount: number;
  patientsCount: number;
  createdBy: Option;
  timeOfCreation: string;
  iconFile?: RemoteFile;
}

export interface PatientClassification {
  id: string;
  clinicyId: string;
  fullNameArabic: string;
  fullNameEnglish: string;
  gender: Option<PatientGender>;
  dateOfBirth: string;
  governmentIssuedIdType: Option<GovernmentIssuedIdType>;
  governmentIssuedId: string;
  patientType: Option<PatientType>;
  toEnable?: boolean;
}

export interface PatientClinicClassification {
  id: string;
  clinicyMrn: string;
  fullNameArabic: string;
  fullNameEnglish: string;
  gender: Option<PatientGender>;
  dateOfBirth: string;
  governmentIssuedIdType: Option<GovernmentIssuedIdType>;
  governmentIssuedId: string;
  patientType: Option<PatientType>;
  toEnable?: boolean;
}

export enum PatientPaymentMethod {
  FromAccountCredit = '3',
  Other = '4',
}

export interface PatientPaymentMethodOption extends Option<PatientPaymentMethod> {
  details?: {
    status: Status;
  };
}

export enum IconCategoryType {
  patientCategory = '1',
}

export enum PatientLogAction {
  Add = '0',
  Edit = '1',
  Remove = '2',
  Block = '3',
  Unblock = '4',
  Merge = '5',
  SwitchDependentToBecomePrimary = '6',
  CreatePrimaryPatientAccount = '7',
  SwitchPrimaryToBecomeDependant = '8',
  ChangedAfterSwitchDependantWithPrimary = '9',
  Unmerge = '10',
}

export enum PatientLogGlobalElementsFilters {
  Blocking = '1',
  FirstGlobalPatientAddition = '10',
  GlobalPatientDetails = '11',
}

export interface PatientLog {
  action: Option<PatientLogAction>;
  actionDateTime: string;
  actionTaker: Option;
  branch: Option;
  element: Option<PatientElementType>;
  fromModel:
    | PatientDetailsElement
    | InsuranceDetailsElement
    | MergingElement
    | PatientMedicalProfileElement
    | StatusElement
    | null;
  id: string;
  medicalInstitution?: Option;
  toModel:
    | PatientDetailsElement
    | InsuranceDetailsElement
    | MergingElement
    | PatientMedicalProfileElement
    | StatusElement
    | null;
}

export interface InsuranceDetailsElement {
  insuranceCompany?: Option;
  status?: Option<Status>;
  policyNumber?: string;
  insuranceNumber?: string;
  insuranceClass?: Option;
  startDate?: string;
  expiryDate?: string;
}

export interface MergingElement {
  patient?: PatientElement;
  mergedPatient?: PatientElement;
}

export type PatientElement = Pick<
  Patient,
  'fullNameArabic' | 'fullNameEnglish' | 'patientId' | 'clinicyMrn' | 'clinicyId'
>;

export interface ParentElement extends PatientElement {
  relation: Option;
  otherRelationText?: string;
}

export interface UserShortInfo extends VerifiedBy {
  mobileNumber: string;
  email: string;
  officeNumber: string;
  nationalId: string;
}

export interface PatientMedicalProfileElement {
  specialityName?: Option;
  emergencyNumber?: string;
  emergencyName?: string;
  emergencyRelation?: Option;
}

export interface StatusElement {
  status?: Option<PatientElementTypeStatus>;
}

export interface PatientDetailsElement extends Patient {
  patientClassification?: Option;
  defaultPatientPhoneNumber?: string;
}

export enum PatientElementType {
  FirstPatientAddition = '0',
  Blocking = '1',
  PatientDetails = '2',
  InsuranceDetails = '3',
  PatientMedicalProfile = '4',
  Dependents = '5',
  Parents = '6',
  Merging = '7',
  PrimaryAndDependent = '8',
  Guardian = '9',
  FirstGlobalPatientAddition = '10',
  GlobalPatientDetails = '11',
}

export enum PatientElementTypeStatus {
  Active = '0',
  Deleted = '1',
  Blocked = '2',
  Splitted = '3',
  Merged = '4',
}

export interface ClinicPatientInfo extends Option {
  details: {
    internalFileNumber?: string;
    patientId: string;
    mobileNumber: string;
    governmentIssuedId: string | null;
    governmentIssuedIdType?: Option;
    clinicyMrn: string;
  };
}

export interface PatientMerge {
  sourceClinicPatient: ClinicPatientInfo;
  destinationClinicPatient: ClinicPatientInfo;
  // [i]: On global level logs, medical institution is null
  medicalInstitution?: Option;
  mergeDateTime: string;
  mergedBy: VerifiedBy;
  numberOfAppointmentsMoved: number;
  numberOfMedicalRecordsMoved: number;
}

//todo: we will use that interface for additional data if user opens modal
export interface ActionTakerInfo {
  mobileNumber: string;
  email: string;
  officeNumber: string;
  nationalId: string;
}

export enum PatientRelation {
  Parent = '0',
  Sibling = '1',
  Family = '2',
  Other = '3',
  Friend = '4',
  Father = '5',
  Mother = '6',
}

export enum PatientEmergencyContactRelation {
  Brother = '0',
  Sibling = '1',
  Family = '2',
  Friend = '3',
  Guardian = '4',
  Caretaker = '5',
  Other = '6',
}

export enum PatientOccupation {
  PrivateSector = '1',
  GovernmentSector = '2',
  Military = '3',
  Student = '4',
  Other = '5',
}

export interface RelatedProfile {
  id: string;
  patientId: string;
  clinicyMrn: string;
  fileNumber: string;
  phoneNumber: string;
  fullName: string;
  patientCategory: Option;
  nationality: Option;
  governmentIssuedIdType: Option<GovernmentIssuedIdType>;
  governmentIssuedId: string;
  patientType: Option<PatientType>;
  matchingRelationType: Option;
  mergePatientsRequestId: string | null;
  invoicesTotal: number;
  lastVisitTime: string;
  verificationStatus: Option<VerificationStatus>;
  attendanceScore: number;
  registeredBy: RegisteredByOption | null;
  upcomingAppointmentsCount: number;
  completedAppointments: number;
  medicalRecordsCount: number;
  status: Option<PatientStatus>;
}

export interface PatientGuardian {
  attendanceScore: number;
  clinicPatientCategory: Option;
  clinicPatientId: string;
  clinicyId: string;
  clinicyMrn: string;
  completedAppointments: number;
  fullNameArabic: string;
  fullNameEnglish: string;
  governmentIssuedId: string;
  governmentIssuedIdType: Option<GovernmentIssuedIdType>;
  hasMergePatientsBadge: boolean;
  id: string;
  internalFileNumber: string;
  invoicesTotal: number;
  medicalInstitution: Option;
  mergeRequestId: string | null;
  nationality: Option;
  otherRelationText: string;
  phoneNumbers: PatientPhoneNumber[];
  registeredBy: RegisteredByOption | null;
  relation: Option<PatientRelation>;
  status: Option<PatientStatus>;
  upcomingAppointments: number;
  verificationStatus: Option;
}

export interface InsuranceDetailsItem {
  clinic: Option;
  clinicBranch: Option;
  clinicPatientId: string;
  clinicPatient: Option;
  deactivated: boolean;
  end: string;
  hasMergePatientsBadge: boolean;
  id: string;
  insuranceClass: InsuranceClassOption;
  insuranceCompany: InsuranceCompany;
  insurancePolicy: PatientInsurancePolicyDetailsOption;
  insuranceContractId: string;
  maxPatientShare: number;
  number: string;
  patientShare: number;
  patientStatus: Option<PatientStatus>;
  start: string;
  status: Option<Status>;
}

export interface PatientInsuranceDetailsOption extends Option {
  details: InsuranceDetailsItem;
}

export interface PatientInsurancePolicyDetailsOption extends Option {
  details: {
    insuranceContractId: string;
    status: Option<InsuranceContractEntityStatus>;
  };
}

export interface PatientAccountBalance {
  totalAccountCreditAmount: number;
  totalInvoices: number;
  totalDebtAmount: number;
  totalDebtAmountDue: number;
  totalBalance: number;
}

export interface PatientStatistics {
  clinicPatientId: string;
  bookedAppointments: number;
  bookedAppointmentsThroughMi: number;
  bookedAppointmentsThroughApp: number;
  canceledByPatient: number;
  canceledByPatientThroughTicket: number;
  canceledByPatientThroughMi: number;
  canceledByPatientThroughApp: number;
  appointmentConfirmation: number;
  appointmentConfirmationThroughTicket: number;
  appointmentConfirmationThroughMi: number;
  appointmentConfirmationThroughApp: number;
  editedAppointments: number;
  editedAppointmentsThroughMi: number;
  editedAppointmentsThroughApp: number;
  noShow: number;
  patientScore: number;
}

export interface MergeProfiles {
  firstName: Option;
  fatherName: Option;
  thirdName: Option;
  lastName: Option;
  aliasName: Option;
  internalPatientFileNumbers: string[];
  clinicyMrn: string;
  gender: Option<PatientGender>;
  dateOfBirth: string;
  nationality: Option;
  governmentIssuedIDType: Option<GovernmentIssuedIdType>;
  governmentIssuedID: string;
  city: Option;
  clinicPatientId: string;
  preferredNotificationLanguage: Option<PreferredLanguage>;
  maritalStatus: Option<PatientMaritalStatus>;
  otherMaritalStatus: string;
  occupation: Option<PatientOccupation>;
  patientCategory: Option;
  email: string;
  emergencyContactName: string;
  emergencyContactNumber: string;
  emergencyContactRelation: Option<PatientEmergencyContactRelation>;
  otherEmergencyContactRelation: string;
  patientSource: Option;
  medicalNote: string;
  phoneNumbers: PatientPhoneNumber[];
}

export interface MergeProfilesData {
  sourcePatient: MergeProfiles;
  destinationPatient: MergeProfiles;
  mergeRequestId?: string;
  result: Patient;
}

export interface PatientCategoryIconOption extends Option {
  details?: {
    iconFile: RemoteFile;
  };
}

export interface PatientLinkHistoryItem {
  clinicyId: string;
  id: string;
  name: Option;
  timeOfCreation: string;
}

export interface PatientBadgeMerge {
  sourceClinicPatient: Option;
  destinationClinicPatient: Option;
  medicalInstitution: Option;
  confirmedBy: Option;
  confirmedAt: string;
}

export interface PatientInvoicesToPay {
  id: string;
  invoiceNumber: string;
  billingItemsBased: boolean;
  createDate: string;
  dueDate: string;
  paymentLastUpdate: string;
  hasMergePatientsBadge: boolean;
  remaining: number;
  totalToPay: number;
}
