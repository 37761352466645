import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import {
  AssignItemToPractitionersBody,
  BranchBillingItemActionParams,
  BranchBillingItemMassAddingAttempt,
  BranchBillingItemMassAddingItemDetails,
  BranchBillingItemMassAddingQueryParams,
  BranchBillingItemWarningsParams,
  DisableBranchBillingItemActionParams,
  EditBillingItemRequestData,
  ExportPractitionerBillingItemsParams,
  PerformerBillingItemActionParams,
  TogglePerformerBillingItemStatusParams,
  UpdatePractitionerBillingItemBody,
} from 'app/services/billingItems/types';
import { GetErrorFileResponse, TriggerMassAddingParams } from 'app/services/massAdding/types';
import { CreateMasterBranchBillingItemValues } from 'app/redux/branchBillingItem/types';
import {
  BillingItem,
  BillingItemMoreInfo,
  MassAddingRow,
  PackageWarning,
  PerformerBillingItem,
  QueryParams,
  QueryResponse,
  QueryResponseWithPagination,
  RemoteFile,
} from 'app/types';

export const branchBillingItemsApi = createApi({
  reducerPath: 'branchBillingItemsApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['BranchBillingItem', 'PerformerBillingItem', 'MassAddingHistory'],
  endpoints: (builder) => ({
    // Branch Billing Items
    exportBranchBillingItems: builder.query<void, { branchId: string; params: QueryParams | undefined }>({
      query: ({ branchId, params }) => ({
        url: `branches/${branchId}/branch-billing-items/excel`,
        method: 'GET',
        params,
      }),
    }),
    getBranchBillingItem: builder.query<QueryResponse<BillingItem>, BranchBillingItemActionParams>({
      query: ({ branchId, branchBillingItemId }) => ({
        url: `branches/${branchId}/branch-billing-items/${branchBillingItemId}`,
        method: 'GET',
        providesTags: ['BranchBillingItem'],
      }),
    }),
    getBranchBillingItemMoreInfo: builder.query<QueryResponse<BillingItemMoreInfo>, BranchBillingItemActionParams>({
      query: ({ branchId, branchBillingItemId }) => ({
        url: `branches/${branchId}/branch-billing-items/${branchBillingItemId}/more-info`,
        method: 'GET',
      }),
    }),
    addBranchBillingItem: builder.mutation<void, { branchId: string; body: CreateMasterBranchBillingItemValues }>({
      query: ({ branchId, body }) => ({
        url: `branches/${branchId}/branch-billing-items`,
        method: 'POST',
        body,
      }),
    }),
    editBranchBillingItem: builder.mutation<void, EditBillingItemRequestData>({
      query: ({ params: { branchId, branchBillingItemId }, body }) => ({
        url: `branches/${branchId}/branch-billing-items/${branchBillingItemId}`,
        method: 'PUT',
        body,
      }),
    }),
    removeBranchBillingItem: builder.mutation<void, BranchBillingItemActionParams>({
      query: ({ branchId, branchBillingItemId }) => ({
        url: `branches/${branchId}/branch-billing-items/${branchBillingItemId}`,
        method: 'DELETE',
      }),
    }),
    enableBranchBillingItem: builder.mutation<void, BranchBillingItemActionParams>({
      query: ({ branchId, branchBillingItemId }) => ({
        url: `branches/${branchId}/branch-billing-items/${branchBillingItemId}/enable`,
        method: 'POST',
      }),
    }),
    disableBranchBillingItem: builder.mutation<void, DisableBranchBillingItemActionParams>({
      query: ({ branchId, branchBillingItemId, removeFromPackages }) => ({
        url: `branches/${branchId}/branch-billing-items/${branchBillingItemId}/disable`,
        method: 'POST',
        params: { removeFromPackages },
      }),
    }),

    // Practitioner's Billing Items
    exportPerformersBillingItems: builder.query<
      void,
      { branchId: string; params: ExportPractitionerBillingItemsParams }
    >({
      query: ({ branchId, params }) => ({
        url: `branches/${branchId}/performer-billing-items/excel`,
        method: 'GET',
        params,
      }),
    }),
    getPerformerBillingItem: builder.query<QueryResponse<PerformerBillingItem>, PerformerBillingItemActionParams>({
      query: ({ branchId, branchBillingItemId, performerBillingItemId }) => ({
        url: `branches/${branchId}/branch-billing-items/${branchBillingItemId}/performer-billing-items/${performerBillingItemId}`,
        method: 'GET',
        providesTags: ['PerformerBillingItem'],
      }),
    }),
    assignItemToPerformers: builder.mutation<void, { branchId: string; body: AssignItemToPractitionersBody }>({
      query: ({ branchId, body }) => ({
        url: `branches/${branchId}/performer-billing-items`,
        method: 'POST',
        body,
      }),
    }),
    editPerformerBillingItem: builder.mutation<
      void,
      { params: PerformerBillingItemActionParams; body: UpdatePractitionerBillingItemBody }
    >({
      query: ({ params: { branchId, branchBillingItemId, performerBillingItemId }, body }) => ({
        url: `branches/${branchId}/branch-billing-items/${branchBillingItemId}/performer-billing-items/${performerBillingItemId}`,
        method: 'PUT',
        body,
      }),
    }),
    removePerformerBillingItem: builder.mutation<void, PerformerBillingItemActionParams>({
      query: ({ branchId, branchBillingItemId, performerBillingItemId }) => ({
        url: `branches/${branchId}/branch-billing-items/${branchBillingItemId}/performer-billing-items/${performerBillingItemId}`,
        method: 'DELETE',
      }),
    }),
    togglePerformerBillingItemStatus: builder.mutation<void, TogglePerformerBillingItemStatusParams>({
      query: ({ branchId, branchBillingItemId, performerBillingItemId, enabled }) => ({
        url: `branches/${branchId}/branch-billing-items/${branchBillingItemId}/performer-billing-items/${performerBillingItemId}/enabled`,
        method: 'PUT',
        params: { enabled },
      }),
    }),

    // Other
    getBranchBillingItemsWarnings: builder.query<
      QueryResponseWithPagination<PackageWarning[]>,
      BranchBillingItemWarningsParams
    >({
      query: ({ branchId, branchBillingItemId, params }) => ({
        url: `branches/${branchId}/branch-billing-items/${branchBillingItemId}/warnings`,
        method: 'GET',
        providesTags: ['PerformerBillingItem'],
        params,
      }),
    }),

    // Mass Adding
    getMassAddingAttempts: builder.query<
      QueryResponseWithPagination<BranchBillingItemMassAddingAttempt[]>,
      { branchId: string; params?: BranchBillingItemMassAddingQueryParams }
    >({
      query: ({ branchId, params }) => ({
        url: `branches/${branchId}/mass-adding-attempts`,
        method: 'GET',
        params,
        providesTags: ['MassAddingHistory'],
      }),
    }),
    getMassAddingReport: builder.query<void, { branchId: string; params?: BranchBillingItemMassAddingQueryParams }>({
      query: ({ branchId, params }) => ({
        url: `branches/${branchId}/mass-adding-attempts/history-excel`,
        method: 'GET',
        params,
      }),
    }),
    getMassAddingAttempt: builder.query<
      QueryResponse<BranchBillingItemMassAddingItemDetails>,
      { branchId: string; massAddingAttemptId: string }
    >({
      query: ({ branchId, massAddingAttemptId }) => ({
        url: `branches/${branchId}/mass-adding-attempts/${massAddingAttemptId}`,
        method: 'GET',
      }),
    }),
    getMassAddingAttemptItems: builder.query<
      QueryResponseWithPagination<MassAddingRow[]>,
      { branchId: string; massAddingAttemptId: string; params?: QueryParams }
    >({
      query: ({ branchId, massAddingAttemptId, params }) => ({
        url: `branches/${branchId}/mass-adding-attempts/${massAddingAttemptId}/rows`,
        method: 'GET',
        params,
      }),
    }),
    getMassAddingErrorsFile: builder.query<GetErrorFileResponse, { branchId: string; massAddingAttemptId: string }>({
      query: ({ branchId, massAddingAttemptId }) => ({
        url: `branches/${branchId}/mass-adding-attempts/${massAddingAttemptId}/errors-excel`,
        method: 'GET',
      }),
    }),
    getMassAddingUploadedFile: builder.query<
      QueryResponse<RemoteFile>,
      { branchId: string; massAddingAttemptId: string }
    >({
      query: ({ branchId, massAddingAttemptId }) => ({
        url: `branches/${branchId}/mass-adding-attempts/${massAddingAttemptId}/uploaded-excel`,
        method: 'GET',
      }),
    }),
    getMassAddingBeforeUpdatesFile: builder.query<
      QueryResponse<RemoteFile>,
      { branchId: string; massAddingAttemptId: string }
    >({
      query: ({ branchId, massAddingAttemptId }) => ({
        url: `branches/${branchId}/mass-adding-attempts/${massAddingAttemptId}/before-updates-excel`,
        method: 'GET',
      }),
    }),
    triggerMassAdding: builder.mutation<void, TriggerMassAddingParams>({
      query: ({ body, branchId }) => ({
        body,
        method: 'POST',
        url: `branches/${branchId}/mass-adding-attempts`,
      }),
      invalidatesTags: ['MassAddingHistory'],
    }),
  }),
});

export const {
  useAddBranchBillingItemMutation,
  useEditBranchBillingItemMutation,
  useRemoveBranchBillingItemMutation,
  useEnableBranchBillingItemMutation,
  useDisableBranchBillingItemMutation,
  useLazyGetBranchBillingItemQuery,
  useLazyGetBranchBillingItemMoreInfoQuery,
  useLazyExportBranchBillingItemsQuery,
  useLazyGetPerformerBillingItemQuery,
  useLazyExportPerformersBillingItemsQuery,
  useAssignItemToPerformersMutation,
  useEditPerformerBillingItemMutation,
  useRemovePerformerBillingItemMutation,
  useTogglePerformerBillingItemStatusMutation,
  useGetBranchBillingItemsWarningsQuery,
  useLazyGetBranchBillingItemsWarningsQuery,
  useGetMassAddingAttemptsQuery,
  useGetMassAddingAttemptQuery,
  useGetMassAddingAttemptItemsQuery,
  useLazyGetMassAddingReportQuery,
  useLazyGetMassAddingErrorsFileQuery,
  useLazyGetMassAddingUploadedFileQuery,
  useLazyGetMassAddingBeforeUpdatesFileQuery,
  useTriggerMassAddingMutation,
} = branchBillingItemsApi;
