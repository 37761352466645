import { put, select } from 'redux-saga/effects';
import { fetchBillingItemsPerformers, fetchPerformerBillingItems } from 'app/redux/remoteData/remoteData.actions';
import { RootState } from 'app/types';

export function* refreshPerformerItemLists(branchId: string, expandedOnly?: boolean) {
  const {
    billingItemsLists: { expandedPerformerId },
    remoteData: {
      billingItemsPerformers: { params },
      performerBillingItems: { params: performerBillingItemsParams },
    },
  }: RootState = yield select();

  if (params) {
    if (!expandedOnly) {
      // refreshing list of available practitioners
      yield put<any>(fetchBillingItemsPerformers(branchId, params, true));
    }

    // refreshing list of performer billing items if expanded
    if (expandedPerformerId && performerBillingItemsParams) {
      yield put<any>(
        fetchPerformerBillingItems(
          branchId,
          expandedPerformerId,
          {
            ...performerBillingItemsParams,
            keyword: undefined,
          },
          true,
        ),
      );
    }
  }
}
