import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { apiMiddleware } from 'redux-api-middleware';
import createSagaMiddleware from 'redux-saga';
import { accountBalancesApi } from 'app/services/accountBalances/accountBalances';
import { appointmentsApi } from 'app/services/appointments/appointments';
import { branchBillingItemsApi } from 'app/services/billingItems/branchBillingItems';
import { branchesApi } from 'app/services/branches/branches';
import { clinicsApi } from 'app/services/clinics/clinics';
import { contractsAndPoliciesExclusionsApi } from 'app/services/contractsAndPoliciesExclusions/contractsAndPoliciesExclusions';
import { doctorsApi } from 'app/services/doctors/doctors';
import { generalApi } from 'app/services/general/general';
import { generalNotificationsApi } from 'app/services/generalNotifications/generalNotifications';
import { iconLibraryApi } from 'app/services/iconLibrary/iconLibrary';
import { insuranceApprovalsApi } from 'app/services/insuranceApprovals/insuranceApprovals';
import { insuranceClassBillingItemsApi } from 'app/services/insuranceClassBillingItems/insuranceClassBillingItems';
import { insuranceCompaniesApi } from 'app/services/insuranceCompanies/insuranceCompanies';
import { insuranceContractorApi } from 'app/services/insuranceContractors/insuranceContractors';
import { insuranceContractApi } from 'app/services/insuranceContracts/insuranceContracts';
import { insurancePoliciesApi } from 'app/services/insurancePolicies/insurancePolicies';
import { claimsApi } from 'app/services/insurances/claims';
import { invoicesApi } from 'app/services/invoices/invoices';
import { massAddingApi } from 'app/services/massAdding/massAdding';
import { medicalLibrariesApi } from 'app/services/medicalLibraries/medicalLibraries';
import { orderApi } from 'app/services/order/order';
import { ordersApi } from 'app/services/orders/orders';
import { patientPaymentsApi } from 'app/services/patientPayments/patientPayments';
import { patientsApi } from 'app/services/patients/patients';
import { patientsArrivalApi } from 'app/services/patientsArrival/patientsArrival';
import { patientSourcesApi } from 'app/services/patientSources/patientSources';
import { referralsApi } from 'app/services/referrals/referrals';
import { specialitiesApi } from 'app/services/specialities/specialities';
import { specialityApi } from 'app/services/speciality/speciality';
import { usersApi } from 'app/services/users/users';
import { authMiddleware } from 'app/middlewares/auth/auth.middleware';
import { bodyMiddleware } from 'app/middlewares/body/body.middleware';
import endpointMiddleware from 'app/middlewares/endpoint/endpoint.middleware';
import { errorMiddleware } from 'app/middlewares/errorMiddleware/errorMiddleware';
import { paramsMiddleware } from 'app/middlewares/params/params.middleware';
import { requestMiddleware } from 'app/middlewares/request/request.middleware';
import { validationMiddleware } from 'app/middlewares/validation/validation.middleware';
import { watchRouteChangeMiddleware } from 'app/middlewares/watch-route/watch-route-change.middleware';

export const sagaMiddleware = createSagaMiddleware();

export const createMiddleware = (history: History<unknown>) => [
  routerMiddleware(history),
  watchRouteChangeMiddleware,
  authMiddleware,
  requestMiddleware,
  bodyMiddleware,
  paramsMiddleware,
  endpointMiddleware,
  validationMiddleware,
  apiMiddleware,
  sagaMiddleware,

  // API
  accountBalancesApi.middleware,
  appointmentsApi.middleware,
  branchesApi.middleware,
  clinicsApi.middleware,
  contractsAndPoliciesExclusionsApi.middleware,
  doctorsApi.middleware,
  generalNotificationsApi.middleware,
  iconLibraryApi.middleware,
  insuranceApprovalsApi.middleware,
  insuranceClassBillingItemsApi.middleware,
  insuranceCompaniesApi.middleware,
  insuranceContractApi.middleware,
  insuranceContractorApi.middleware,
  insurancePoliciesApi.middleware,
  invoicesApi.middleware,
  massAddingApi.middleware,
  medicalLibrariesApi.middleware,
  orderApi.middleware,
  ordersApi.middleware,
  patientSourcesApi.middleware,
  patientsApi.middleware,
  patientPaymentsApi.middleware,
  patientsArrivalApi.middleware,
  referralsApi.middleware,
  specialityApi.middleware,
  specialitiesApi.middleware,
  usersApi.middleware,
  generalApi.middleware,
  claimsApi.middleware,
  branchBillingItemsApi.middleware,

  // New Middlewares
  errorMiddleware,
];
