import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BranchBillingItemState } from 'app/redux/branchBillingItem/types';

const initialState: BranchBillingItemState = {
  selectedItemParams: null,
};

const branchBillingItem = createSlice({
  name: 'branchBillingItem',
  initialState,
  reducers: {
    setSelectedItemParams: (state, action: PayloadAction<{ branchId: string; branchBillingItemId: string }>) => {
      state.selectedItemParams = action.payload;
    },
  },
});

export const { setSelectedItemParams } = branchBillingItem.actions;

export default branchBillingItem.reducer;
