import { useMemo } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { checkAccess, useCurrentUser } from 'app/helpers';
import { UserRoles } from 'app/types';

interface Props {
  allowedRoles: UserRoles[];
  component: RouteProps['component'];
  denialPath?: string;
  exact?: RouteProps['exact'];
  location?: RouteProps['location']; // provided by ConnectedRouter
  path: RouteProps['path'];
}
export interface PrivateRouteState {
  referrer?: string;
  userId?: string;
}

export const PrivateRoute = ({
  allowedRoles,
  component,
  denialPath = '/access-denied',
  exact,
  location,
  path,
}: Props) => {
  const { currentUser } = useCurrentUser();
  const hasAccess = useMemo(() => checkAccess(allowedRoles, currentUser.roles), [allowedRoles, currentUser.roles]);
  const state: PrivateRouteState = { referrer: location?.pathname, userId: currentUser.id };

  if (hasAccess) {
    return <Route exact={exact} path={path} component={component} />;
  } else {
    return (
      <Redirect
        to={{
          pathname: denialPath,
          state,
        }}
      />
    );
  }
};
