import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

export const AccessDeniedSection = () => (
  <>
    <h1 className="mb-2">
      <FormattedMessage id="ACCESS-DENIED.TEXT.ACCESS-TITLE" />
    </h1>
    <h3 className="fw-light mb-4">
      <FormattedMessage id="ACCESS-DENIED.TEXT.ACCESS-DESCRIPTION" />
    </h3>
    <Button color="primary-gradient" tag={Link} to="/">
      <FormattedMessage id="CORE.BUTTON.HOME-PAGE" />
    </Button>
  </>
);
