import { put, select } from 'redux-saga/effects';
import { invoicesApi } from 'app/services/invoices/invoices';
import { RootState } from 'app/types';

export function* refreshInvoicesRequest() {
  const {
    queryParams: { invoicesRequests: params },
  }: RootState = yield select();

  yield put<any>(
    invoicesApi.endpoints.getInvoicesRequests.initiate({ ...params }, { subscribe: false, forceRefetch: true }),
  );
}
