import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import {
  CreateInsuranceReferralRequestBodyParams,
  GetInsuranceApprovalActionsParams,
  GetInsuranceReferralActionsParams,
  InsuranceApprovalBody,
} from 'app/services/insuranceApprovals/types';
import {
  DetailedInsuranceApproval,
  DetailedInsuranceReferral,
  InsuranceApprovalAction,
  InsuranceApprovalRequest,
  InsuranceApprovalsPageAction,
  InsuranceReferral,
  InsuranceReferralAction,
  InsuranceReferralsPageAction,
  QueryParams,
  QueryResponse,
  QueryResponseWithPagination,
} from 'app/types';

export const insuranceApprovalsApi = createApi({
  reducerPath: 'insuranceApprovalsApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    'InsuranceApprovals',
    'InsuranceReferrals',
    'InsuranceApprovalsLogs',
    'InsuranceReferralsLogs',
    'InsuranceApprovalLogs',
    'InsuranceReferralLogs',
  ],
  endpoints: (builder) => ({
    getInsuranceApprovals: builder.query<
      QueryResponseWithPagination<InsuranceApprovalRequest[]>,
      QueryParams | undefined
    >({
      query: (params) => ({
        url: 'insurance-approvals',
        params,
      }),
      providesTags: ['InsuranceApprovals'],
    }),

    getInsuranceReferrals: builder.query<QueryResponseWithPagination<InsuranceReferral[]>, QueryParams | undefined>({
      query: (params) => ({
        url: 'referral-insurance-approvals',
        method: 'GET',
        params,
      }),
      providesTags: ['InsuranceReferrals'],
    }),

    getInsuranceApprovalsLogs: builder.query<
      QueryResponseWithPagination<InsuranceApprovalsPageAction[]>,
      QueryParams | undefined
    >({
      query: (params) => ({
        url: '/insurance-approvals/action-logs',
        method: 'GET',
        params,
      }),
      providesTags: ['InsuranceApprovalsLogs'],
    }),

    getInsuranceApprovalLogs: builder.query<
      QueryResponseWithPagination<InsuranceApprovalAction[]>,
      GetInsuranceApprovalActionsParams
    >({
      query: ({ insuranceApprovalId, params }) => ({
        url: '/insurance-approvals/action-logs',
        method: 'GET',
        params: {
          ...params,
          insuranceApprovalId,
        },
      }),
      providesTags: ['InsuranceApprovalLogs'],
    }),

    getInsuranceReferralsLogs: builder.query<
      QueryResponseWithPagination<InsuranceReferralsPageAction[]>,
      QueryParams | undefined
    >({
      query: (params) => ({
        url: '/referral-insurance-approvals/action-logs',
        method: 'GET',
        params,
      }),
    }),

    getInsuranceReferralLogs: builder.query<
      QueryResponseWithPagination<InsuranceReferralAction[]>,
      GetInsuranceReferralActionsParams
    >({
      query: ({ insuranceReferralId, params }) => ({
        url: '/referral-insurance-approvals/action-logs',
        method: 'GET',
        params: {
          ...params,
          insuranceReferralId,
        },
      }),
      providesTags: ['InsuranceReferralLogs'],
    }),

    cancelInsuranceReferral: builder.mutation<void, { insuranceReferralId: string }>({
      query: ({ insuranceReferralId }) => ({
        url: `/referral-insurance-approvals/${insuranceReferralId}/cancel`,
        method: 'PUT',
      }),
      invalidatesTags: ['InsuranceReferrals', 'InsuranceReferralLogs'],
    }),
    editInsuranceApproval: builder.mutation<
      void,
      { insuranceApprovalId: string; insuranceApprovalResponseId: string; body: InsuranceApprovalBody }
    >({
      query: ({ insuranceApprovalId, insuranceApprovalResponseId, body }) => ({
        url: `/insurance-approvals/${insuranceApprovalId}/insurance-approval-responses/${insuranceApprovalResponseId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['InsuranceApprovals', 'InsuranceApprovalLogs', 'InsuranceApprovalsLogs'],
    }),
    addInsuranceApproval: builder.mutation<void, { insuranceApprovalId: string; body: InsuranceApprovalBody }>({
      query: ({ insuranceApprovalId, body }) => ({
        url: `/insurance-approvals/${insuranceApprovalId}/insurance-approval-responses`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InsuranceApprovals', 'InsuranceApprovalLogs', 'InsuranceApprovalsLogs'],
    }),
    createInvoiceRequest: builder.mutation<void, { insuranceApprovalId: string }>({
      query: ({ insuranceApprovalId }) => ({
        url: `/insurance-approvals/${insuranceApprovalId}/invoice-requests`,
        method: 'POST',
      }),
      invalidatesTags: ['InsuranceApprovals', 'InsuranceApprovalsLogs'],
    }),

    createInsuranceReferralRequest: builder.mutation<void, CreateInsuranceReferralRequestBodyParams>({
      query: (body) => ({
        url: '/referral-insurance-approvals',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InsuranceReferrals', 'InsuranceReferralsLogs'],
    }),

    getDetailedInsuranceReferral: builder.query<
      QueryResponse<DetailedInsuranceReferral>,
      { insuranceReferralId: string }
    >({
      query: ({ insuranceReferralId }) => ({
        url: `/referral-insurance-approvals/${insuranceReferralId}`,
        method: 'GET',
      }),
    }),

    getDetailedInsuranceApproval: builder.query<
      QueryResponse<DetailedInsuranceApproval>,
      { insuranceApprovalId: string }
    >({
      query: ({ insuranceApprovalId }) => ({
        url: `/insurance-approvals/${insuranceApprovalId}/detailed`,
        method: 'GET',
      }),
    }),

    getInsuranceApprovalsReport: builder.query<void, QueryParams | undefined>({
      query: (params) => ({
        url: '/insurance-approvals/excel',
        params,
      }),
    }),

    getInsuranceReferralsReport: builder.query<void, QueryParams | undefined>({
      query: (params) => ({
        url: '/referral-insurance-approvals/excel',
        params,
      }),
    }),
  }),
});

export const {
  useLazyGetInsuranceReferralsReportQuery,
  useLazyGetInsuranceApprovalsReportQuery,
  useEditInsuranceApprovalMutation,
  useAddInsuranceApprovalMutation,
  useGetDetailedInsuranceApprovalQuery,
  useGetDetailedInsuranceReferralQuery,
  useCreateInsuranceReferralRequestMutation,
  useGetInsuranceReferralLogsQuery,
  useGetInsuranceApprovalLogsQuery,
  useCreateInvoiceRequestMutation,
  useCancelInsuranceReferralMutation,
  useGetInsuranceApprovalsQuery,
  useGetInsuranceReferralsQuery,
  useGetInsuranceApprovalsLogsQuery,
  useGetInsuranceReferralsLogsQuery,
} = insuranceApprovalsApi;
