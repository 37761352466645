import React, { useMemo } from 'react';
import { formattedDateAndTime, useAppIntl } from 'app/helpers';
import { ProcedureResponse } from 'app/redux/doctorAssessment/types';
import styles from 'app/features/doctorAssessment/components/medicalProfileSection/components/helpers/printDataFormatter/printDataFormatter.module.scss';

interface Props {
  data: ProcedureResponse[];
  title: string;
}

export const PrintDataFormatter = ({ data, title }: Props) => {
  const { formatMessage } = useAppIntl();
  const timeNow = useMemo(() => formattedDateAndTime(new Date()), []);

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{formatMessage({ id: title })}</h2>
      {data.map((item, index) => (
        <div key={index} className={styles.itemWrapper}>
          <h2 className={styles.title}>
            {formatMessage({ id: 'CORE.TABLE.DATE' })}:{' '}
            {item.createdAt ? formattedDateAndTime(item.createdAt) : timeNow}
          </h2>
          <span className={styles.content} dangerouslySetInnerHTML={{ __html: item.text }} />
          {index !== data.length - 1 && <hr className={styles.separator} />}
        </div>
      ))}
    </div>
  );
};
