import { PayloadAction } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { put, select } from 'redux-saga/effects';
import { setStep } from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { InvoicingProcessPayload, InvoicingProcessStep } from 'app/redux/invoicingProcess/types';
import { Locale, RootState } from 'app/types';
import { initializeInvoicingProcess } from 'app/sagas/invoicingProcess/generators/initializeInvoicingProcess';
import { refreshInvoiceList } from 'app/sagas/invoicingProcess/generators/refreshInvoiceList';

export function* afterCreateInvoiceSuccess({ payload }: PayloadAction<InvoicingProcessPayload | undefined>) {
  const {
    intl: { locale },
  }: RootState = yield select();

  if (payload?.data) {
    yield initializeInvoicingProcess(payload.data, { locale: locale as Locale });
    yield put<any>(setStep(InvoicingProcessStep.Summary));
    yield toast.success('CORE.TEXT.IP-CREATED-SUCCESSFULLY');
    yield refreshInvoiceList();
  }
}
