import { FormattedMessage } from 'react-intl';
import { Loader } from 'app/shared';

export const LoggingSection = () => (
  <>
    <h1 className="mb-2">
      <FormattedMessage id="ACCESS-DENIED.TEXT.LOGGING-TITLE" />
    </h1>
    <h3 className="fw-light mb-4">
      <FormattedMessage id="ACCESS-DENIED.TEXT.LOGGING-DESCRIPTION" />
    </h3>
    <Loader />
  </>
);
